import React,{ useEffect, useState} from "react";
import { getDashboardData } from "../actions/dash-board.action";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSelector } from "../selector/dash-board.selector";
import { isValidArray } from "../utils/utilities";
import { DonutComponent } from "../components/dashboard-components/donut-chart.component";
import { Row,Col } from 'antd';
import { DailySummaryComponent } from "../components/dashboard-components/summary.component";
import { BookedTodayComponent } from "../components/dashboard-components/booked-today.component";
import { MonthlyOccupancyComponent } from "../components/dashboard-components/monthly-occupancy";
import { RevenueForWeekComponent } from "../components/dashboard-components/revenue-for-this-week";
import { loadingAction } from "../actions/loading-actions";
import { getAPIDateFormat, currentDate } from "../utils/date.utils";

export const DashboardPage = () => {

    const dispatch = useDispatch();
    const [donutData,setDonutData] = useState([]);

    const dashboardData = useSelector(dashboardSelector);

    const onSuccess=()=>{
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }


    useEffect(()=>{
        setLoading(true);
        dispatch(getDashboardData(getAPIDateFormat(currentDate()),onSuccess,onFailure));
    },[]);

    const resetData=()=>{
        setDonutData([]);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    useEffect(()=>{
        if(isValidArray(dashboardData)){
            for (let index = 0; index < dashboardData.length; index++) {
                const element = dashboardData[index];
                if(element.entity == "Donut"){
                    setDonutData(element);
                }
            }
        }else{
            resetData();
        }
    },[dashboardData]);

    return (
        <div className="dashboard-page">
            <Row>
                <Col>
                    <DonutComponent 
                        donutData={donutData}
                    />
                </Col>
                <Col>
                    <DailySummaryComponent 
                    
                    />
                    <RevenueForWeekComponent 
                    
                    />
                </Col>
                <Col>
                    <BookedTodayComponent
                    
                    />
                    {/* <MonthlyOccupancyComponent
                    
                    /> */}
                </Col>
            </Row>
        </div>
    );                      
}


