import { API_ADMIN_BASE_URL, API_BASE_URL, API_B2B_ADMIN_BASE_URL, API_SRMS_ADMIN_BASE_URL, API_SRMS_BASE_URL } from "../app.config";

/**
 * URL's related to location data
 */
export const USER_LOGIN_URL = `${API_BASE_URL}/api/agentLogin`;

/**
 * URL's related to Dashboard
 */

export const GET_DASHBOARD_DATA_URL = `${API_ADMIN_BASE_URL}/api/getInspectData`;

/**
 * URL 's related to origins
 */

export const GET_ORIGIN_CITIES_URL = `${API_BASE_URL}/api/origins`;
export const GET_DESTINATION_CITIES_URL = `${API_BASE_URL}/api/destinations`; 

export const FETCH_SCHEDULES_URL = `${API_BASE_URL}/api/schedules`;

export const FETCH_SEAT_LAYOUT_URL = `${API_BASE_URL}/api/seats`;

export const BOOK_TICKET_URL = `${API_BASE_URL}/api/bookFireboltTicket`;
export const CHANGE_TICKET_URL = `${API_BASE_URL}/api/changeTicket`;

export const BLOCK_TICKET_URL = `${API_BASE_URL}/api/blockFireboltTicket`;

export const GET_ROUTES_BY_SCHEDULE_ID_URL = `${API_ADMIN_BASE_URL}/api/getRoutesByScheduleId`;
export const RE_ACTIVATE_TRIP_ROUTE_BY_TRIP_ID= `${API_ADMIN_BASE_URL}/api/reActivateTripRoutesByTripId`;
export const GET_TRAVELLER_INFO_TICKETID_AND_SEAT_NUMBER_URL = `${API_BASE_URL}/api/getTravellerByTicketIdAndSeatNumber`;
export const GET_ALL_TICKET_DETAILS_URL=`${API_BASE_URL}/api/getAllRoutesByTripId`;
export const SEND_BUS_CANCELLATION_EMAIL_URL = `${API_BASE_URL}/api/sendBusCancellationMailToAgent`;
export const BLOCK_ALL_AVAILABLE_SEATS_OF_TRIP = `${API_BASE_URL}/api/blockAllAvailableSeatsByTripId`;
export const CHANGE_PASSWORD_URL = `${API_BASE_URL}/api/changePassword`;
export const NOTIFICATION_DELAY_BUS_URL = `${API_BASE_URL}/api/notifications/delayBus`;
export const NOTIFICATION_CANCEL_BUS_URL = `${API_BASE_URL}/api/notifications/delayBus`;
export const SEND_CANCELLATION_NOTIFICATION_URL = `${API_BASE_URL}/api/notifications/cancelBus`;
export const GET_TICKET_BY_PNR_URL = `${API_BASE_URL}/api/getTicketByPNR`;
export const GET_TICKET_BY_ID_URL = `${API_BASE_URL}/api/getTicketById`;
export const GET_TICKET_PNR_BY_ID_URL = `${API_BASE_URL}/api/getTicketPNRById`;
export const CANCEL_TICKET_URL = `${API_BASE_URL}/api/cancelTicket`;
export const UPDATE_TICKET_URL = `${API_BASE_URL}/api/updateTicket`;
export const TRIP_SHEET_URL = `${API_BASE_URL}/api/reports/tripSheet`;
export const FETCH_CANCELLED_TRIP_SHEET_URL = `${API_BASE_URL}/api/reports/getCancelledSeatsByTripId`;
export const GET_REFUND_ARN_BY_ORDER_ID = `${API_BASE_URL}/api/getRefundARN`;

export const BOOKING_SHEET_URL = `${API_BASE_URL}/api/reports/bookedSheet`;
export const BOOKING_SRMS_SHEET_URL = `${API_SRMS_BASE_URL}/api/reports/bookedSheet`;
export const SMS_REPORT_SHEET_URL = `${API_BASE_URL}/api/reports/getsmsMessages`;
export const GET_TICKETS_BOOKED_AFTER_TRIP_CLOSE_POINT_URL = `${API_BASE_URL}/api/reports/getTicketsBookedAfterTripClosePoint`;
export const GET_SETTLEMENT_REPORT_URL = `${API_BASE_URL}/api/reports/getSettlementReport`;
export const SALES_SHEET_URL = `${API_BASE_URL}/api/reports/salesSheet`;
export const MASTER_TRIP_SHEET_URL = `${API_BASE_URL}/api/reports/masterTripSheet`;
export const PASSENGER_INFO_SHEET_URL = `${API_BASE_URL}/api/reports/passengerInfoSheet`;
export const TICKET_SEARCH_URL = `${API_BASE_URL}/api/reports/ticketSearch`;
export const RELEASE_SEATS_URL = `${API_BASE_URL}/api/releaseSeats`;
export const BLOCK_BY_ADMIN_SEATS_URL = `${API_BASE_URL}/api/adminBlockSeats`;
export const GET_UNIQUE_ACTIVE_ROUTES_URL = `${API_ADMIN_BASE_URL}/api/getUniqueActiveRoutesByOperatorCode`;
export const UPDATE_ROUTE_PRIORITY_URL = `${API_ADMIN_BASE_URL}/api/updateRoutePriority`;

export const GET_DEMAND_DATA_SHEET_URL = `${API_ADMIN_BASE_URL}/api/reports/fetchDemandDataSheet`;
export const GET_GST_FILING_TICKETS_URL = `${API_BASE_URL}/api/reports/getGstFilingTickets`;
export const GET_SEAT_SHARING_REPORT_URL = `${API_BASE_URL}/api/reports/getSeatSharingReport`;

export const OVER_RIDE_PENALITY_URL = `${API_BASE_URL}/api/overridePenalty`;

export const GET_TRIPS_BY_BOOKING_DATE_URL = `${API_BASE_URL}/api/getTripsByBookedDateRange`;
export const GET_TRIPS_BY_START_DATE_URL = `${API_BASE_URL}/api/getTripsByStartDateRange`;
export const BLOCK_AVAILABLE_SEATS_URL = `${API_BASE_URL}/api/blockAvailableSeats`;
export const SET_SEAT_GENDER_URL = `${API_BASE_URL}/api/setSeatGender`;
export const BLOCKED_SHEET_URL = `${API_BASE_URL}/api/reports/blockedSheet`;
export const RESCHEDULED_TICKET_REPORT_URL = `${API_BASE_URL}/api/reports/rescheduledTicketReport`;
export const PERFORMANCE_URL = `${API_BASE_URL}/api/reports/performance`;
export const TRANSACTION_SHEET_URL = `${API_BASE_URL}/api/reports/transactionSheet`;
export const CANCEL_TICKET_SEARCH_URL = `${API_BASE_URL}/api/reports/cancelticketsearch`;

export const ABANDONED_TRANSACTION_SHEET_URL = `${API_BASE_URL}/api/reports/abandonedTransactionsSheet`;
export const WEB_TICKET_SALES_SHEET_URL = `${API_BASE_URL}/api/reports/webTicketSales`;

export const CREATE_TRIP_EXPENSE_URL = `${API_BASE_URL}/api/createTripExpense`;
export const GET_TRIP_EXPENSE_URL = `${API_BASE_URL}/api/getExpenseByTripId`;
export const UPDATE_TRIP_EXPENSE_URL = `${API_BASE_URL}/api/updateTripExpense`;

export const SEND_PAYMENT_LINK_URL = `${API_BASE_URL}/api/sendPaymentLinkByPnr`;

export const GET_BUS_OPERATORS_URL = `${API_BASE_URL}/api/getActiveTravels`;
export const UPDATE_TRIP_ROUTE_TIMINGS_URL = `${API_ADMIN_BASE_URL}/api/updateTripTimeByTripId`;
export const CACHE_REFRESH_TRIP_ROUTE_TIMING_URL = `${API_BASE_URL}/api/refreshTripRouteAndLocation`;

// Get vechicle numbers
export const GET_VECHICLES_URL = `${API_ADMIN_BASE_URL}/api/vechicleInfo`;
export const GET_TRIP_ROUTES_BY_SCHEDULE_ID_AND_DATE_URL = `${API_ADMIN_BASE_URL}/api/getTripRoutesByScheduleAndDate`;
export const UPDATE_FARE_FOR_TRIP_ROUTES_URL = `${API_ADMIN_BASE_URL}/api/updateFareForTripRoutes`;
export const UPDATE_TRIPS_URL = `${API_ADMIN_BASE_URL}/api/updateTrips`;
export const UPDATE_TRIP_VECHICLE_PHONE_NO_URL = `${API_ADMIN_BASE_URL}/api/updateTripVechiclePhoneNo`;

//Agents urls
export const GET_AGENTS_URL = `${API_ADMIN_BASE_URL}/api/getAgents`;
export const GET_AGENTS_TRANSACTIONS_URL = `${API_ADMIN_BASE_URL}/api/getAgentTransactionsByOperatorCode`;
export const CREATE_AGENT_TRANSACTION_URL = `${API_ADMIN_BASE_URL}/api/createAgentTransaction`;
export const GET_AGENT_TRANSACTION_URL = `${API_ADMIN_BASE_URL}/api/createAgentTransaction`;
export const UPDATE_AGENT_TYPE_URL = `${API_ADMIN_BASE_URL}/api/updatePostPaidAgent`;

export const UPDATE_AGENT_TRANSACTION_STATUS_URL = `${API_ADMIN_BASE_URL}/api/updateAgentTransaction`;
export const GET_AGENTS_BY_OPERATOR_CODE_URL = `${API_BASE_URL}/api/getAgentsByOperatorCode`;
export const GET_PREPAID_OTA_ACCOUNT_URL = `${API_ADMIN_BASE_URL}/api/getPrepaidOTAAccountData`;

// Note:Specific to srms
export const GET_SRMS_PREPAID_OTA_ACCOUNT_URL = `${API_SRMS_ADMIN_BASE_URL}/api/getPrepaidOTAAccountData`;

//Driver urls
export const GET_DRIVERS_URL = `${API_ADMIN_BASE_URL}/api/getDriverById`;
export const CREATE_DRIVER_URL = `${API_B2B_ADMIN_BASE_URL}/api/insertDriversManagement`
export const UPDATE_DRIVER_URL = `${API_B2B_ADMIN_BASE_URL}/api/updateFromDriversManagement`;
export const DELETE_DRIVER_URL = `${API_B2B_ADMIN_BASE_URL}/api/deleteFromDriversManagement`;

//Staff urls
export const GET_STAFF_TYPES_URL = `${API_ADMIN_BASE_URL}/api/getStaffTypes`;
export const GET_STAFF_URL = `${API_ADMIN_BASE_URL}/api/getStaff`;
export const CREATE_STAFF_URL = `${API_ADMIN_BASE_URL}/api/createStaff`;
export const DELETE_STAFF_URL = `${API_ADMIN_BASE_URL}/api/deleteStaffById`;
export const UPDATE_STAFF_URL = `${API_ADMIN_BASE_URL}/api/updateStaff`;

// Otp urls
export const VERIFY_OTP_URL = `${API_BASE_URL}/api/otp/verifyPNOTP`;

//Trip related urls
export const FETCH_MASTER_CITIES_URL = `${API_ADMIN_BASE_URL}/api/getMasterCities`;
export const GET_ADMIN_SCHEDULES_URL = `${API_ADMIN_BASE_URL}/api/getSchedules`;
export const GET_GOOGLE_DISTANCE_MATRIX_URL = `${API_ADMIN_BASE_URL}/api/getDistanceDataBetweenCities`;
export const GET_BUS_URL = `${API_ADMIN_BASE_URL}/api/getBuses`;
export const CREATE_TRIP_DATA_URL = `${API_ADMIN_BASE_URL}/api/createScheduleByAgent`;
export const UPDATE_TRIP_DATA_URL = `${API_ADMIN_BASE_URL}/api/updateScheduleByAgent`;
export const GET_SCHEDULE_TRIP_CITY_URL = `${API_ADMIN_BASE_URL}/api/getScheduleTripCity`;
export const GET_ADDRESS_LOCATIONS_URL = `${API_ADMIN_BASE_URL}/api/getAddressLocations`;
export const INSERT_TRIP_STOPS_URL = `${API_ADMIN_BASE_URL}/api/insertTripStops`;
export const GET_SCHEDULE_WITH_STOPS_URL = `${API_ADMIN_BASE_URL}/api/getScheduleWithStops`;
export const INSERT_SCHEDULE_BY_STOPS_URL = `${API_ADMIN_BASE_URL}/api/insertScheduleByStops`;
export const GET_SCHEDULES_BY_ID_FOR_DATES_URL = `${API_ADMIN_BASE_URL}/api/getSchedulesByIdForDate`;
export const ACTIVATE_SCHEDULES_URL = `${API_ADMIN_BASE_URL}/api/activateTrips`;
export const BLOCK_SCHEDULES_URL = `${API_ADMIN_BASE_URL}/api/blockTrips`;
export const VALIDATE_TRIP_URL =`${API_ADMIN_BASE_URL}/api/validateSchedule`;
export const GET_DEMAND_DAYS_URL = `${API_ADMIN_BASE_URL}/api/fetchDemandDays`;


export const REMOVE_SCHEDULES_URL = `${API_ADMIN_BASE_URL}/api/removeSchedule`;

export const GET_SEAT_LAYOUT_BY_BUS_ID_URL = `${API_ADMIN_BASE_URL}/api/getSeatLayoutByBusId`;
export const CREATE_ADDRESS_URL = `${API_ADMIN_BASE_URL}/api/createLocation`;
export const UPDATE_ADDRESS_URL = `${API_ADMIN_BASE_URL}/api/updateLocation`;

export const GET_DISTANCE_BETWEEN_TWO_CITIES_URL = `${API_ADMIN_BASE_URL}/api/getDistanceDataBetweenCities`;

export const GET_LAT_LNG_OF_ADDRESS_URL = `${API_ADMIN_BASE_URL}/api/getLatLngofAddress`;
export const FETCH_ACTIVE_BOOKINGS_URL = `${API_BASE_URL}/api/reports/getActiveBookings`;
export const FETCH_SERVICE_COUNT_REPORT_URL = `${API_BASE_URL}/api/reports/getServiceCountReport`;


export const GET_STANDARD_STOPS__URL = `${API_ADMIN_BASE_URL}/api/getStandardAddresses`;
export const CREATE_ADDRESS_LOCATION_URL = `${API_ADMIN_BASE_URL}/api/createLocation`;
export const UPDATE_ADDRESS_LOCATION_URL = `${API_ADMIN_BASE_URL}/api/updateLocation`;
export const DELETE_ADDRESS_LOCATION_URL = `${API_ADMIN_BASE_URL}/api/deleteLocationById`;

export const UPDATE_STOP_TIMINGS_URL = `${API_ADMIN_BASE_URL}/api/updateStopTimings`;
export const GET_PICKUP_LOCATION_BY_TRIP_ID_URL = `${API_BASE_URL}/api/getPickupLocationByTripId`;
export const GET_DROP_LOCATION_BY_TRIP_ID_URL = `${API_BASE_URL}/api/getDropLocationByTripId`;
export const GET_AMENITIES_FOR_SCHEDULE_URL = `${API_ADMIN_BASE_URL}/api/getAmenitiesForSchedule`;
export const FETCH_AMENITIES_URL = `${API_ADMIN_BASE_URL}/api/fetchAmenities`;
export const ADD_AMENITIES_TO_SCHEDULE_URL = `${API_ADMIN_BASE_URL}/api/addAmenitiesToSchedule`
export const TRIP_ACTIVATION_MAIL_OTAS_URL = `${API_BASE_URL}/api/sendTripActivateEmailToOtas`;

// Trip sheet permissions 

export const GET_SCHEDULES_WITH_TRIP_SHEET_PERMISSION_URL=`${API_ADMIN_BASE_URL}/api/getSchedulesWithTripSheetPermission`;
export const INSERT_TRIP_SHEET_PERMISSION_URL=`${API_ADMIN_BASE_URL}/api/insertTripSheetPermission`;
export const UPDATE_TRIP_SHEET_PERMISSION_URL=`${API_ADMIN_BASE_URL}/api/updateTripSheetPermission`;
export const DELETE_TRIP_SHEET_PERMISSION_URL=`${API_ADMIN_BASE_URL}/api/deleteTripSheetPermission`;
export const BLUK_UPDATE_TRIP_SHEET_PERMISSION_URL=`${API_ADMIN_BASE_URL}/api/blukUpdateTripSheetPermission`;

// release all blocked seats

export const RELEASE_ALL_BLOCKED_SEATS_URL = `${API_BASE_URL}/api/releaseBlockedSeatsByTripId`;

export const GET_SCHEDULE_STOPS_BY_TRIP_ID_URL = `${API_ADMIN_BASE_URL}/api/getScheduleStopsByTripIdWithCities`;

export const RESET_TO_DEFAULT_TIMINGS_URL = `${API_ADMIN_BASE_URL}/api/resetToDefaultTimings`;

export const UPDATE_STOP_TIMING_OF_TRIP_URL = `${API_ADMIN_BASE_URL}/api/updateStopTimingsOfTrip`;

export const FETCH_LOGS_TRIP_URL = `${API_ADMIN_BASE_URL}/api/fetchLogsByTripId`;

export const ACTIVATE_REDBUS_CITY_PAIR_URL = `${API_ADMIN_BASE_URL}/api/activateRedbusCityPair`;

export const SEND_TRACKING_NOTIFICATION_BY_TICKET_ID_URL = `${API_BASE_URL}/api/sendTrackingNotification`;
export const SEND_TRACKING_NOTIFICATION_BY_TRIP_ID_URL = `${API_BASE_URL}/api/sendNotificationByTripIds`;
export const GET_TRACKING_INFO_BY_TRIP_ID_URL = `${API_BASE_URL}/api/getTrackingInfoByTripId`;

//schedule related urls

export const GET_DROP_LOCATION_BY_SCHEDULE_ID_URL = `${API_ADMIN_BASE_URL}/api/getDropLocationsByScheduleId`;
export const GET_PICKUP_LOCATION_BY_SCHEDULE_ID_URL = `${API_ADMIN_BASE_URL}/api/getPickupLocationsByScheduleId`;

export const SEND_UPDATED_TICKET_MAIL_URL = `${API_BASE_URL}/api/sendBookedTicket`;
export const SEND_UPDATED_TICKET_SMS_URL = `${API_BASE_URL}/api/sendBookedTicketSms`;

export const UPDATE_TRIP_CUT_ROUTE_URL = `${API_ADMIN_BASE_URL}/api/updateTripCutRouteByDate`;
export const UPDATE_TRIP_BP_CHECK_URL = `${API_ADMIN_BASE_URL}/api/updateTripBpCheckByDate`;

export const FETCH_WORKFLOW_REPORT_URL = `${API_BASE_URL}/api/getWorkflowReport`;
export const FETCH_TRANSACTION_COUNT_REPORT_URL = `${API_BASE_URL}/api/reports/getTransactionCountDetails`;
export const FETCH_PRE_PAID_TRANSACTION_COUNT_REPORT_URL = `${API_BASE_URL}/api/reports/mavenTransaction`;
export const FETCH_SRMS_PRE_PAID_TRANSACTION_COUNT_REPORT_URL = `${API_SRMS_BASE_URL}/api/reports/mavenTransaction`;

export const FETCH_REVENUE_REPORT_URL = `${API_BASE_URL}/api/reports/revenue`;

export const FETCH_REVENUE_FOR_WEEK_OTA_URL = `${API_BASE_URL}/api/reports/getDashBoardRevenueForOTA`;

export const FETCH_CITY_DETAILS_URL = `${API_ADMIN_BASE_URL}/api/getcitiesDetails`;
export const FETCH_TODAYS_TICKET_COUNT_URL = `${API_ADMIN_BASE_URL}/api/fetchTodaysTicketCount`;

export const UPDATE_CITY_PHONE_NO_URL = `${API_ADMIN_BASE_URL}/api/updateCityPhoneNumber`;

export const UPDATE_BP_AND_DP_URL = `${API_ADMIN_BASE_URL}/api/bulkupdatePickupandDropLocation`;

export const GET_BP_FROM_ADMIN_URL = `${API_ADMIN_BASE_URL}/api/getPickupLocations`;
export const GET_DP_FROM_ADMIN_URL = `${API_ADMIN_BASE_URL}/api/getDropLocations`;

export const UPDATE_BOARDING_POINT_TIME_URL = `${API_ADMIN_BASE_URL}/api/updateBoardingPointTimeById`;

export const UPDATE_DROP_POINT_TIME_URL = `${API_ADMIN_BASE_URL}/api/updateDropPointTimeById`;

export const UPDATE_TRIP_DYNAMIC_PRICING_URL = `${API_ADMIN_BASE_URL}/api/updateTripDynamicPricing`;

export const UPDATE_TRIP_DYNAMIC_FARE_URL = `${API_ADMIN_BASE_URL}/api/updateDynamicFareForTrip`;

export const DELETE_STOPS_URL = `${API_ADMIN_BASE_URL}/api/deleteStops`;

export const ADD_STOPS_URL = `${API_ADMIN_BASE_URL}/api/addStops`;

export const COPY_TRAVELLER_INFO_TO_SEAT_URL = `${API_BASE_URL}/api/copyTravellerInfoSeat`;

export const CLEAR_TRAVELLER_INFO_FROM_SEAT_URL = `${API_BASE_URL}/api/clearSeats`;

export const REGISTER_REDBUS_DP_ABOUT_SCHEDULE_URL = `${API_ADMIN_BASE_URL}/api/registerRedBusDPAboutSchedule`;

export const DE_REGISTER_REDBUS_DP_ABOUT_SCHEDULE_URL = `${API_ADMIN_BASE_URL}/api/informRedBusDPAboutScheduleDeRegister`;

export const CHANGE_SEAT_LAYOUT_URL = `${API_ADMIN_BASE_URL}/api/changeSeatLayout`;

export const INFORM_OTA_FOR_TRIP_CHANGES_URL = `${API_ADMIN_BASE_URL}/api/informOTAforTripChanges`;

export const CLONE_TRIP_URL =`${API_ADMIN_BASE_URL}/api/cloneSchedule`;
export const CLONE_AS_RETURN_TRIP_URL =`${API_ADMIN_BASE_URL}/api/cloneAsReturnSchedule`;

export const REFRESH_CACHE_FOR_DROP_POINTS_URL = `${API_BASE_URL}/api/refreshCacheForDropPoints`;
export const REFRESH_CACHE_FOR_BOARDING_POINTS_URL = `${API_BASE_URL}/api/refreshCacheForBoardingPoints`;

export const UPDATE_PHONE_NUMBERS_OF_ADDRESS_BOOK_URL = `${API_ADMIN_BASE_URL}/api/updatePhoneNumbersOfAddressBook`;

export const GET_PARTNER_CODE_URL = `${API_ADMIN_BASE_URL}/api/getPartnerDetails`;

export const UPDATE_PARTNER_URL=`${API_ADMIN_BASE_URL}/api/updatePartner`;

export const FETCH_TRACKING_DATA_REPORT_URL = `${API_ADMIN_BASE_URL}/report/getTrackingReportByDate`;

export const UPDATE_SUBSCRIBED_VALUE_URL = `${API_ADMIN_BASE_URL}/api/changeSubscription`;

export const  IN_ACTIVATE_TRIPS_CREATED_URL = `${API_ADMIN_BASE_URL}/api/inactivateTripsCreated`;

export const ISSUE_AUTOMATIC_REUND_BY_TRAVELLER_URL = `${API_BASE_URL}/api/issueAutomaticRefundByTraveller`;

export const ACTIVATE_IN_OTAS_URL = `${API_ADMIN_BASE_URL}/api/activateInOtas`;

export const FETCH_REVENUE_DETAIL_URL = `${API_ADMIN_BASE_URL}/api/getTripRevenueDetailsByTripId`;

// AGENT ACTIONS 
export const GET_AGENT_ACTIONS_URL = `${API_ADMIN_BASE_URL}/api/getAgentActions`;

export const GET_USER_ACTIONS_URL = `${API_ADMIN_BASE_URL}/api/getUserActions`;
export const INSERT_USER_PRIVILEGE_URL= `${API_ADMIN_BASE_URL}/api/insertUserPrivilege`;
export const DELETE_USER_PRIVILEGE_URL= `${API_ADMIN_BASE_URL}/api/deleteUserPrivilege`;